import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f589852"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "cp-layout-relative" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_app_table = _resolveComponent("app-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          class: "page-loader-overlay_table"
        }))
      : (Object.keys(_ctx.table.titles).length)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small",
            headers: _ctx.table.titles,
            items: _ctx.table.rows,
            "total-pages": _ctx.table.pagination.totalPages,
            page: _ctx.table.pagination.currentPage,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "total-items": _ctx.table.totalItems,
            "is-hover": true,
            onChangePage: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-employee": _withCtx(({ value }) => [
              _createVNode(_component_router_link, {
                class: "app-table-link",
                target: "_blank",
                to: { name: 'employees_schedule', params: { employeeId: value.id } }
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(value.name), 1)
                ]),
                _: 2
              }, 1032, ["to"])
            ]),
            _: 1
          }, 8, ["headers", "items", "total-pages", "page", "page-items", "total-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true)
  ]))
}
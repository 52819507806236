import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';
import PageEntity from '@/lib/layouts/page/pageEntity';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { prepareList } from '@/lib/Recruits';
import { getMailingParticipants } from '@/api/mailings';
import { PageSort } from '@/lib/layouts/page/page.interface';
import { strings } from '@/lib/stringConst';

export const MODULE_NAME = 'recipients';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Recipients extends PageBaseModule {
  currentMailingId = 0;

  constructor(module: Recipients) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'recipients/updatePage';
    this.pageSettings = page.values;
  }

  @Mutation
  SET_CURRENT_MAILING_ID(value: number) {
    this.currentMailingId = value;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.recipientsSort = JSON.stringify(sorts);
  }

  @Action({ rawError: true })
  async updatePage(number: string) {
    this.context.commit('SET_PAGE', parseInt(number));
    await this.getList();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const sort = await this.getSortForRequest();
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const filter = 'filters[1][id]=mailing&filters[1][value]=' + this.currentMailingId;

      const result: TableApiInterface = await getMailingParticipants(
        this.pageSettings.pageCurrent,
        itemsQuery,
        sort,
        filter
      );

      (result.titles as { id: string; visible: boolean }[]).map((item: { id: string; visible: boolean }) => {
        if (item.id === 'mailing' || item.id === 'scheduledDate' || item.id === 'sentAt' || item.id === 'createdAt') {
          item.visible = false;
        }
      });

      await this.context.dispatch('setList', result);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    this.context.commit('SET_TABLE', prepareList(table, this));
  }

  @Action({ rawError: true })
  async init(mailingId: number) {
    await this.context.commit('SET_CURRENT_MAILING_ID', mailingId);

    this.context.commit(
      'SET_SORT',
      window.localStorage.recipientsSort ? JSON.parse(window.localStorage.recipientsSort) : {}
    );

    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);

    await this.getList();
  }
}

export default getModule(Recipients);

import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, withModifiers as _withModifiers, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex" }
const _hoisted_3 = {
  ref: "filter",
  class: "cp-flex cp-flex--justify-between align-items-end"
}
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_form_multi_select = _resolveComponent("form-multi-select")!
  const _component_app_header_main = _resolveComponent("app-header-main")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_text_datetime = _resolveComponent("text-datetime")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_mailings_employees_list = _resolveComponent("mailings-employees-list")!
  const _component_modal_block = _resolveComponent("modal-block")!
  const _component_app_row = _resolveComponent("app-row")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createElementVNode("div", _hoisted_2, [
              _withDirectives(_createVNode(_component_app_header_btn_add, { onClick: _ctx.toAdd }, null, 8, ["onClick"]), [
                [_directive_tooltip, 'Создание рассылки']
              ])
            ])
          ]),
          _createElementVNode("form", _hoisted_3, [
            (!_ctx.isLoading)
              ? (_openBlock(), _createBlock(_component_form_multi_select, {
                  key: 0,
                  label: _ctx.inlineFilterSettings.status.title,
                  modelValue: 
            _ctx.inlineFilterSettings.status.list.filter((item) => {
              return item.checked;
            })
          ,
                  items: _ctx.inlineFilterSettings.status.list,
                  onUpdateItem: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateStatus($event, { key: 'status', action: _ctx.inlineFilterSettings.status.action }))),
                  class: "cp-admin-title__wrapper",
                  filter: ""
                }, null, 8, ["label", "modelValue", "items"]))
              : _createCommentVNode("", true),
            _createVNode(_component_app_header_main, {
              "filter-settings": _ctx.filterSettings,
              "is-to-add-hidden": true,
              onResetFilters: _ctx.resetFilters,
              onUpdateList: _ctx.updateList
            }, null, 8, ["filter-settings", "onResetFilters", "onUpdateList"])
          ], 512)
        ]),
        _: 1
      }),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      (!_ctx.isLoading && Object.keys(_ctx.table.titles).length)
        ? (_openBlock(), _createBlock(_component_app_table, {
            key: 1,
            class: "cp-margin__t--small",
            headers: _ctx.table.titles,
            items: _ctx.table.rows,
            "total-pages": _ctx.table.pagination.totalPages,
            page: _ctx.table.pagination.currentPage,
            "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
            "total-items": _ctx.table.totalItems,
            "is-hover": true,
            onClickRow: _cache[1] || (_cache[1] = ($event: any) => (_ctx.toEdit($event.item.id))),
            onChangePage: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
            onSelectAmount: _ctx.selectAmount,
            onSort: _ctx.sort
          }, {
            "item-createdAt": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-to": "DD/MM/YY HH:mm"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-finishedAt": _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createBlock(_component_text_datetime, {
                    key: 0,
                    value: value,
                    "format-to": "DD/MM/YY HH:mm"
                  }, null, 8, ["value"]))
                : _createCommentVNode("", true)
            ]),
            "item-count": _withCtx(({ value, item }) => [
              (value !== 0)
                ? (_openBlock(), _createElementBlock("span", {
                    key: 0,
                    class: "cp-link cp-link__14 cp-cursor-pointer",
                    onClick: _withModifiers(($event: any) => (_ctx.openModal(item.id, item.name)), ["stop"])
                  }, _toDisplayString(value), 9, _hoisted_4))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, "-"))
            ]),
            _: 1
          }, 8, ["headers", "items", "total-pages", "page", "page-items", "total-items", "onSelectAmount", "onSort"]))
        : _createCommentVNode("", true),
      (_ctx.recipientsModalShown)
        ? (_openBlock(), _createBlock(_component_modal_block, {
            key: 2,
            class: "cp-modal__xl",
            title: _ctx.mailingName,
            onClick: _ctx.closeModal
          }, {
            default: _withCtx(() => [
              _createVNode(_component_mailings_employees_list, { mailing: _ctx.mailingId }, null, 8, ["mailing"])
            ]),
            _: 1
          }, 8, ["title", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}

import { Component, Prop, Vue } from '@/lib/decorator';
import AppTable from '@/components/ui/table/Table.vue';
import Loading from '@/components/Loading.vue';
import RecipientsModule from '@/store/settings/mailings/recipients.ts';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';

@Component({
  components: {
    AppTable,
    Loading,
  },
})
export default class MailingsEmployeesList extends Vue {
  @Prop({ required: true }) mailing = 0;
  isLoading = true;

  get table(): {} | TableInterface {
    return this.settings.table;
  }

  get settings(): PageInterface {
    return RecipientsModule.pageSettings;
  }

  selectAmount(value: string): void {
    RecipientsModule.updatePageAmountItems(value);
  }

  async sort(header: { id: string; sort: { value: string } }): Promise<void> {
    await RecipientsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async created(): Promise<void> {
    await RecipientsModule.init(this.mailing);

    this.isLoading = false;
  }
}

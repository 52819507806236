
import { Component, Vue } from '@/lib/decorator';

import AppHeaderMain from '@/layouts/partials/AppHeaderMain.vue';
import TitleReturn from '@/components/TitleReturn.vue';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import AppTable from '@/components/ui/table/Table.vue';

import SettingsModule from '@/store/settings';
import MailingsModule from '@/store/settings/mailings/index';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';
import TextDatetime from '@/components/table-items/TextDatetime.vue';

import ModalBlock from '@/components/Modal.vue';
import MailingsEmployeesList from './employeesList.vue';

import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import { Filter, FilterModel, FilterSettings } from '@/lib/layouts/page/filter.interface';
import { TableInterface } from '@/lib/layouts/page/table.interface';
import { PageInterface } from '@/lib/layouts/page/page.interface';
import { TabsNavInterface } from '@/interfaces/tabs.interface';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    TextDatetime,
    FormMultiSelect,
    AppHeaderBtnAdd,
    AppHeaderMain,
    TabsNav,
    TitleReturn,
    AppTable,
    ModalBlock,
    MailingsEmployeesList,
    AppCol,
    AppRow,
  },
})
export default class MailingsMain extends Vue {
  isLoading = true;
  mailingId = 0;
  mailingName = '';

  get filterSettings(): FilterSettings {
    return MailingsModule.filter.filterSettings;
  }

  get inlineFilter(): Filter {
    return MailingsModule.inlineFilter;
  }

  get inlineFilterSettings(): FilterModel {
    return this.inlineFilter.filterSettings.filterModel;
  }

  get table(): TableInterface | {} {
    return this.settings.table;
  }

  get settings(): PageInterface {
    return MailingsModule.pageSettings;
  }

  get tabsNav(): TabsNavInterface {
    return SettingsModule.tabsNav;
  }

  get currentTab(): string {
    return SettingsModule.allTabs.mailings?.id;
  }

  get recipientsModalShown(): boolean {
    return MailingsModule.recipientsModalShown;
  }

  toAdd(): void {
    this.$router.push({ name: 'mailings_add' });
  }

  toEdit(id: string): void {
    this.$router.push({ name: 'mailings_edit', params: { id } });
  }

  selectAmount(value: string): void {
    MailingsModule.updatePageAmountItems(value);
  }

  async sort(header: { id: string; sort: { value: string } }): Promise<void> {
    await MailingsModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async resetFilters(): Promise<void> {
    await MailingsModule.setPageToBegin();
    await MailingsModule.filter.resetFilter();
    await MailingsModule.getList();
  }

  async updateList(): Promise<void> {
    await MailingsModule.setPageToBegin();
    await MailingsModule.filter.updateFilter();
    await MailingsModule.inlineFilter.updateFilter();
    await MailingsModule.getList();
  }

  async updateStatus(selected: { id: string; value: string }, params: { action: string; key: string }): Promise<void> {
    await this.$store.dispatch(params.action, {
      key: params.key,
      value: selected,
    });

    await MailingsModule.filter.updateFilter();
    await MailingsModule.inlineFilter.updateFilter();
    await this.updateList();
  }

  async created(): Promise<void> {
    this.inlineFilter.updateMultiSelectList({ key: 'status', list: await MailingsModule.initState() });
    await MailingsModule.init();

    this.isLoading = false;
  }

  openModal(id: number, name: string): void {
    this.mailingId = id;
    this.mailingName = 'Список получателей "' + name + '"';
    this.$refs.filter.click();
    MailingsModule.openRecipientsModal();
  }

  closeModal(): void {
    MailingsModule.closeRecipientsModal();
  }

  mounted(): void {
    SettingsModule.initTabsItems();
  }

  $refs!: {
    filter: HTMLElement;
  };
}
